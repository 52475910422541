exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contato-tsx": () => import("./../../../src/pages/contato.tsx" /* webpackChunkName: "component---src-pages-contato-tsx" */),
  "component---src-pages-galeria-tsx": () => import("./../../../src/pages/galeria.tsx" /* webpackChunkName: "component---src-pages-galeria-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lp-tsx": () => import("./../../../src/pages/lp.tsx" /* webpackChunkName: "component---src-pages-lp-tsx" */),
  "component---src-pages-servicos-markdown-remark-frontmatter-path-tsx": () => import("./../../../src/pages/servicos/{markdownRemark.frontmatter__path}.tsx" /* webpackChunkName: "component---src-pages-servicos-markdown-remark-frontmatter-path-tsx" */),
  "component---src-pages-servicos-tsx": () => import("./../../../src/pages/servicos.tsx" /* webpackChunkName: "component---src-pages-servicos-tsx" */)
}

